import { useTranslation } from 'react-i18next';

const IconLightBulb = () => {
  const { t } = useTranslation();

  return (
    <svg
      width="48"
      height="50"
      viewBox="0 0 48 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{t('icon.lightBulb')}</title>
      <path
        d="M12.6631 10.4687C12.4846 10.4687 12.3188 10.4046 12.1785 10.2636L7.02655 5.08681C6.89902 4.95867 6.82251 4.77928 6.82251 4.59988C6.82251 4.42049 6.88627 4.25391 7.02655 4.11295C7.29435 3.84386 7.71518 3.84386 7.98298 4.11295L13.1349 9.28978C13.2625 9.41792 13.339 9.59732 13.339 9.77671C13.339 9.95611 13.2752 10.1227 13.1349 10.2636C12.9947 10.4046 12.8289 10.4687 12.6504 10.4687H12.6631Z"
        fill="#00548E"
      />
      <path
        d="M28.0935 44.0539H19.8937C19.5367 44.0539 19.2561 43.7719 19.2561 43.4132C19.2561 43.0544 19.5367 42.7725 19.8937 42.7725H28.0935C28.4506 42.7725 28.7311 43.0544 28.7311 43.4132C28.7311 43.7719 28.4506 44.0539 28.0935 44.0539Z"
        fill="#00548E"
      />
      <path
        d="M28.0935 47.2062H19.8937C19.5367 47.2062 19.2561 46.9243 19.2561 46.5655C19.2561 46.2067 19.5367 45.9248 19.8937 45.9248H28.0935C28.4506 45.9248 28.7311 46.2067 28.7311 46.5655C28.7311 46.9243 28.4506 47.2062 28.0935 47.2062Z"
        fill="#00548E"
      />
      <path
        d="M28.0935 50.0001H19.8937C19.5367 50.0001 19.2561 49.7182 19.2561 49.3594C19.2561 49.0007 19.5367 48.7188 19.8937 48.7188H28.0935C28.4506 48.7188 28.7311 49.0007 28.7311 49.3594C28.7311 49.7182 28.4506 50.0001 28.0935 50.0001Z"
        fill="#00548E"
      />
      <path
        d="M19.9448 28.2667C19.83 28.2667 19.7153 28.2411 19.6132 28.177C17.4453 26.8444 16.0426 24.6404 15.762 22.1032C15.4942 19.6814 16.2976 17.298 17.9809 15.5425C18.2232 15.2862 18.6313 15.2734 18.8864 15.5297C19.1414 15.7731 19.1542 16.1832 18.8991 16.4395C17.4836 17.9131 16.795 19.9377 17.0245 21.9751C17.254 24.115 18.44 25.973 20.2764 27.1007C20.5824 27.2801 20.6717 27.6773 20.4932 27.9848C20.3784 28.1898 20.1616 28.2924 19.9448 28.2924V28.2667Z"
        fill="#00548E"
      />
      <path
        d="M20.5569 15.1073C20.3146 15.1073 20.0978 14.9792 19.983 14.7485C19.83 14.4282 19.9575 14.0438 20.2763 13.89C20.7354 13.6722 21.22 13.4928 21.7173 13.3518C22.0616 13.2749 22.406 13.4672 22.4952 13.8003C22.5845 14.1463 22.3805 14.4923 22.0489 14.582C21.6281 14.6973 21.22 14.8511 20.8374 15.0305C20.7481 15.0689 20.6461 15.0945 20.5569 15.0945V15.1073Z"
        fill="#00548E"
      />
      <path
        d="M27.9278 40.6071H20.0596C19.6898 40.6071 19.3837 40.2995 19.3837 39.9279C19.3837 36.1606 17.9172 32.6112 15.2647 29.9459C12.931 27.6137 11.6558 24.4999 11.6558 21.1811C11.6558 14.3513 17.1903 8.79004 23.9873 8.79004C30.7843 8.79004 36.3189 14.3513 36.3189 21.1811C36.3189 24.4999 35.0309 27.6137 32.71 29.9459C30.0575 32.6112 28.5909 36.1606 28.5909 39.9279C28.5909 40.2995 28.2849 40.6071 27.9151 40.6071H27.9278ZM20.7227 39.2488H27.2647C27.4305 35.3662 29.0118 31.7526 31.7663 28.9848C33.8449 26.8961 34.9799 24.1283 34.9799 21.1811C34.9799 15.0945 30.0575 10.1483 24.0001 10.1483C17.9427 10.1483 13.0203 15.0945 13.0203 21.1811C13.0203 24.1283 14.168 26.909 16.2339 28.9848C18.9756 31.7526 20.5569 35.3662 20.7355 39.2488H20.7227Z"
        fill="#00548E"
      />
      <path
        d="M24 5.75346C23.6302 5.75346 23.3241 5.44593 23.3241 5.07432V0.679139C23.3241 0.307535 23.6302 0 24 0C24.3698 0 24.6758 0.307535 24.6758 0.679139V5.07432C24.6758 5.44593 24.3698 5.75346 24 5.75346Z"
        fill="#00548E"
      />
      <path
        d="M17.8661 6.98361C17.5983 6.98361 17.3432 6.82984 17.2412 6.56075L15.5707 2.49873C15.5324 2.40903 15.5197 2.31934 15.5197 2.24245C15.5197 1.97336 15.6727 1.71708 15.9405 1.61457C16.2848 1.47362 16.6801 1.6402 16.8204 1.98617L18.491 6.04819C18.5292 6.13789 18.542 6.22759 18.542 6.30447C18.542 6.57357 18.3889 6.82984 18.1211 6.93235C18.0319 6.9708 17.9426 6.98361 17.8661 6.98361Z"
        fill="#00548E"
      />
      <path
        d="M9.18176 15.6969C9.0925 15.6969 9.00323 15.6841 8.92672 15.6456L4.88421 13.967C4.62916 13.8645 4.46338 13.6082 4.46338 13.3391C4.46338 13.2494 4.47613 13.1597 4.51439 13.0829C4.65466 12.7369 5.04999 12.5703 5.3943 12.7113L9.43681 14.3899C9.69186 14.4924 9.85764 14.7487 9.85764 15.0178C9.85764 15.1075 9.84489 15.1972 9.80663 15.274C9.70461 15.5303 9.44956 15.6969 9.18176 15.6969Z"
        fill="#00548E"
      />
      <path
        d="M42.848 29.7155C42.7588 29.7155 42.6695 29.7026 42.593 29.6642L38.5505 27.9856C38.2954 27.8831 38.1296 27.6268 38.1296 27.3577C38.1296 27.268 38.1424 27.1783 38.1806 27.1014C38.3209 26.7554 38.7162 26.5889 39.0606 26.7298L43.1031 28.4084C43.3581 28.5109 43.5239 28.7672 43.5239 29.0363C43.5239 29.126 43.5111 29.2157 43.4729 29.2926C43.3709 29.5489 43.1158 29.7155 42.848 29.7155Z"
        fill="#00548E"
      />
      <path
        d="M30.1339 6.98361C30.0446 6.98361 29.9554 6.9708 29.8788 6.93235C29.6238 6.82984 29.458 6.57357 29.458 6.30447C29.458 6.21478 29.4708 6.12508 29.509 6.04819L31.1796 1.98617C31.3199 1.6402 31.7152 1.47362 32.0595 1.61457C32.3145 1.71708 32.4803 1.97336 32.4803 2.24245C32.4803 2.33215 32.4676 2.42185 32.4293 2.49873L30.7588 6.56075C30.6567 6.81703 30.4017 6.98361 30.1339 6.98361Z"
        fill="#00548E"
      />
      <path
        d="M5.13926 29.7155C4.87146 29.7155 4.61641 29.5617 4.51439 29.2926C4.47613 29.2029 4.46338 29.126 4.46338 29.0363C4.46338 28.7672 4.61641 28.5109 4.88421 28.4084L8.92672 26.7298C9.27103 26.5889 9.66635 26.7554 9.80663 27.1014C9.84489 27.1911 9.85764 27.268 9.85764 27.3577C9.85764 27.6268 9.70461 27.8831 9.43681 27.9856L5.3943 29.6642C5.30504 29.7026 5.21577 29.7155 5.13926 29.7155Z"
        fill="#00548E"
      />
      <path
        d="M38.8055 15.6969C38.5377 15.6969 38.2827 15.5431 38.1806 15.274C38.1424 15.1843 38.1296 15.1075 38.1296 15.0178C38.1296 14.7487 38.2827 14.4924 38.5505 14.3899L42.593 12.7113C42.9373 12.5703 43.3326 12.7369 43.4729 13.0829C43.5111 13.1726 43.5239 13.2494 43.5239 13.3391C43.5239 13.6082 43.3709 13.8645 43.1031 13.967L39.0606 15.6456C38.9713 15.6841 38.882 15.6969 38.8055 15.6969Z"
        fill="#00548E"
      />
      <path
        d="M7.97025 21.8602H0.675877C0.306057 21.8602 0 21.5527 0 21.1811C0 20.8095 0.306057 20.502 0.675877 20.502H7.97025C8.34007 20.502 8.64612 20.8095 8.64612 21.1811C8.64612 21.5527 8.34007 21.8602 7.97025 21.8602Z"
        fill="#00548E"
      />
      <path
        d="M47.3241 21.8602H40.0298C39.6599 21.8602 39.3539 21.5527 39.3539 21.1811C39.3539 20.8095 39.6599 20.502 40.0298 20.502H47.3241C47.6939 20.502 48 20.8095 48 21.1811C48 21.5527 47.6939 21.8602 47.3241 21.8602Z"
        fill="#00548E"
      />
      <path
        d="M7.51114 38.4423C7.33261 38.4423 7.16682 38.3782 7.02655 38.2373C6.88627 38.0963 6.82251 37.9297 6.82251 37.7503C6.82251 37.5709 6.88627 37.4044 7.02655 37.2634L12.1785 32.0866C12.4463 31.8175 12.8671 31.8175 13.1349 32.0866C13.2625 32.2147 13.339 32.3941 13.339 32.5735C13.339 32.7529 13.2752 32.9195 13.1349 33.0604L7.98298 38.2373C7.85545 38.3654 7.67692 38.4423 7.49839 38.4423H7.51114Z"
        fill="#00548E"
      />
      <path
        d="M35.3368 10.4687C35.1583 10.4687 34.9925 10.4046 34.8522 10.2636C34.712 10.1227 34.6482 9.95611 34.6482 9.77671C34.6482 9.59732 34.712 9.43074 34.8522 9.28978L40.0042 4.11295C40.272 3.84386 40.6928 3.84386 40.9606 4.11295C41.0881 4.24109 41.1647 4.42049 41.1647 4.59988C41.1647 4.77928 41.1009 4.94586 40.9606 5.08681L35.8087 10.2636C35.6811 10.3918 35.5026 10.4687 35.3241 10.4687H35.3368Z"
        fill="#00548E"
      />
      <path
        d="M40.4888 38.4286C40.3103 38.4286 40.1445 38.3646 40.0042 38.2236L34.8522 33.0468C34.7247 32.9186 34.6482 32.7392 34.6482 32.5598C34.6482 32.3804 34.712 32.2139 34.8522 32.0729C35.12 31.8038 35.5409 31.8038 35.8087 32.0729L40.9606 37.2497C41.0881 37.3779 41.1647 37.5573 41.1647 37.7367C41.1647 37.9161 41.1009 38.0827 40.9606 38.2236C40.8331 38.3517 40.6546 38.4286 40.476 38.4286H40.4888Z"
        fill="#00548E"
      />
    </svg>
  );
};

export default IconLightBulb;
