import { create } from 'zustand';
import { getEpicUrl } from '../helpers/urlUtils';
import { FHIRAccessTokenJWT } from '../interfaces/FHIRAccessTokenJWT';
import { LoginStore } from '../interfaces/LoginStore';
import { Member } from '../interfaces/Epic';

type LoginStoreAction = {
  setIncorrectLogin: (incorrectLogin: boolean) => void;
  setIsLoadingLogin: (isLoadingLogin: boolean) => void;
  handleLogin: () => void;
  handleLogout: () => void;
  setAuthorizeToken: (
    authorizeToken: FHIRAccessTokenJWT | null,
    callback?: () => void
  ) => void;
  addProxyUsers: (members: Member[]) => void;
  setNeedsDataRefresh: (needsDataRefresh: boolean) => void;
};

const useLoginStore = create<LoginStore & LoginStoreAction>((set, get) => ({
  isLoggedIn: false,
  isLoadingLogin: false,
  incorrectLogin: document.referrer.includes('errorKey=loginfailed')
    ? true
    : false,
  authorizeToken: null,
  members: [],
  setIncorrectLogin: (incorrectLogin: boolean) => set({ incorrectLogin }),
  setIsLoadingLogin: (isLoadingLogin: boolean) => set({ isLoadingLogin }),
  handleLogin: () => set(state => ({ isLoggedIn: true })),
  handleLogout: () => {
    if (get().isLoggedIn) {
      window.location.href = `${getEpicUrl()}Home/Logout`;
    }
    set(() => ({ isLoggedIn: false, authorizeToken: null, members: [] }));
  },
  setAuthorizeToken: (
    authorizeToken: FHIRAccessTokenJWT | null,
    callback?: () => void
  ) => {
    set({ authorizeToken });
    if (typeof callback === 'function') {
      callback();
    }
  },
  addProxyUsers: (members: Member[] = []) => {
    set(state => {
      const newMembers = [...state.members, ...members];
      const uniqueMembers = newMembers.filter(
        (member, index, self) =>
          index === self.findIndex(m => m.eid === member.eid)
      );

      return {
        members: uniqueMembers,
      };
    });
  },
  needsDataRefresh: false,
  setNeedsDataRefresh: (needsDataRefresh: boolean) => set({ needsDataRefresh }),
}));

export default useLoginStore;
