import { FaqCategoryResponse, FaqQuestionResponse } from '../interfaces/Faq';
import api from './createApi';

/**
 *
 * @param catId
 * @param locale
 * @returns
 */
export const getRelatedCategories = async (
  faqMainId: string,
  locale: string
) => {
  return await api
    .get<FaqCategoryResponse>(`faqmains(${faqMainId})/FAQCategories`, {
      params: { sf_culture: locale },
    })
    .then(res => res.data.value);
};

/**
 *
 * @param catId
 * @param locale
 * @returns
 */
export const getRelatedSubcategories = async (
  catId: string,
  locale: string
) => {
  return (await api.get)<FaqCategoryResponse>(
    `faqcategories(${catId})/Subcategories`,
    {
      params: { sf_culture: locale },
    }
  ).then(res => res.data.value);
};

/**
 *
 * @param subCatId
 * @param locale
 * @returns
 */
export const getRelatedQuestions = async (subCatId: string, locale: string) => {
  return await api
    .get<FaqQuestionResponse>(`faqsubcategories(${subCatId})/Questions`, {
      params: { sf_culture: locale },
    })
    .then(res => res.data.value);
};
