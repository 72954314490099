import './PrivateFormPage.scss';
import EpicIframe from '../../components/EpicIframe/EpicIframe';
import BreadcrumbLayout from '../../layouts/BreadcrumbLayout/BreadcrumbLayout';
import { t } from 'i18next';

type PrivateFormPageProps = {
  title: string;
  src: string;
  height?: number;
  setTitleFormat?: boolean;
  excerpt?: string;
};

const PrivateFormPage = ({
  title,
  src,
  height,
  setTitleFormat = true,
  excerpt,
}: PrivateFormPageProps) => {
  const heightAttr = height ? { height: height } : {};

  return (
    <BreadcrumbLayout
      pageTitle={title}
      theme="large"
      setTitleFormat={setTitleFormat}
    >
      <div
        className="container-large private-form-page"
        data-testid="private-form-page"
      >
        <EpicIframe title={title} url={src} width={960} {...heightAttr} />
        {excerpt && (
          <div
            className="private-form-page__excerpt"
            dangerouslySetInnerHTML={{ __html: t(excerpt) }}
          ></div>
        )}
      </div>
    </BreadcrumbLayout>
  );
};

export default PrivateFormPage;
