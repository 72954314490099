import './PreventionAndWellness.scss';
import CardSecondary from '../../components/CardSecondary/CardSecondary';
import HomeButton from '../../components/HomeButton/HomeButton';
import PrimaryIconLink from '../../components/PrimaryIconLink/PrimaryIconLink';
import ResourceLink from '../../components/ResourceLink/ResourceLink';
import ThreeColumnCards from '../../components/ThreeColumnCards/ThreeColumnCards';
import TwoColumnCardGrid from '../../components/TwoColumnCardGrid/TwoColumnCardGrid';
import { CardSecondaryProps } from '../../interfaces/CardSecondary';
import { HomeButtonProps } from '../../interfaces/HomeButton';
import { PrimaryIconLinkProps } from '../../interfaces/PrimaryIconLink';
import { ResourceLinkProps } from '../../interfaces/ResourceLink';
import BreadcrumbLayout from '../../layouts/BreadcrumbLayout/BreadcrumbLayout';
import { useTranslation } from 'react-i18next';
import useEpicApiStore from '../../store/useEpicApiStore';
import IconBook from '../../components/ShpIcons/IconBook';
import { useBehavioralHealthUrl } from '../../hooks/useBehavioralHealthUrl';
import { Link } from 'react-router-dom';
import IconRightArrow from '../../components/IconRightArrow/IconRightArrow';

const PreventionAndWellness = () => {
  const { t } = useTranslation();
  const { hasMedicare, eduAssigned } = useEpicApiStore();
  const url = useBehavioralHealthUrl();

  const buttons: HomeButtonProps[] = t('wellness.buttons', {
    returnObjects: true,
  });

  const resourceCards: CardSecondaryProps[] = t('wellness.resources.cards', {
    returnObjects: true,
  });

  const cards: ResourceLinkProps[] = t('wellness.discounts.cards', {
    returnObjects: true,
  });

  const ctaBlockHealth: PrimaryIconLinkProps = t('wellness.ctaBlockHealth', {
    returnObjects: true,
  });

  resourceCards.forEach(card => {
    if (card.link === 'BehavorialHealthLinkPlaceholder') {
      card.link = url;
    }
  });

  return (
    <BreadcrumbLayout
      className="wellness-page"
      pageTitle={t('wellness.pageName')}
    >
      <>
        <div className="wellness-page__buttons">
          <div className="container">
            <TwoColumnCardGrid>
              {buttons.map(button => (
                <HomeButton
                  key={button.label}
                  label={button.label}
                  icon={button.icon}
                  link={
                    hasMedicare && button.medicareLink
                      ? button.medicareLink
                      : button.link
                  }
                  openInNewTab={button.openInNewTab}
                />
              ))}
            </TwoColumnCardGrid>
          </div>
        </div>
        <div className="container wellness-page__resources">
          <h2 className="wellness-page__resources-heading">
            {t('wellness.resources.title')}
          </h2>
          <ThreeColumnCards>
            {resourceCards.map(card => (
              <CardSecondary key={card.title} {...card} />
            ))}
          </ThreeColumnCards>
        </div>
        <div className="wellness-page__discounts">
          <div className="container">
            <h2 className="wellness-page__discounts-heading">
              {t('wellness.discounts.title')}
            </h2>
            <TwoColumnCardGrid>
              {cards.map((link, index) => (
                <ResourceLink {...link} key={index} />
              ))}
            </TwoColumnCardGrid>
          </div>
        </div>
        <div className="wellness-page__callouts">
          <div className="container">
            <PrimaryIconLink {...ctaBlockHealth}>
              <IconBook />
            </PrimaryIconLink>
            {eduAssigned && (
              <Link
                to={`/${t('routes.findCare.index')}/${t(
                  'routes.findCare.healthLibrary'
                )}`}
                className="primary-icon-link -health-library-personalized"
              >
                <span>{t('wellness.personalizedHealth.title')}</span>
                <IconRightArrow />
              </Link>
            )}
          </div>
        </div>
      </>
    </BreadcrumbLayout>
  );
};

export default PreventionAndWellness;
