import { useQuery } from '@tanstack/react-query';
import { getPage } from '../api/pagesApi';

const useMainNavigationQuery = (locale: string) => {
  const pageUrl = locale !== 'en' ? locale : 'home';

  const { data: navigationData, isLoading }: any = useQuery(
    [`home-${locale}`, locale],
    () => getPage(pageUrl, locale),
    {
      select: (data: any) => {
        const mainNav = JSON.parse(data[0].Properties.SerializedSelectedPages);
        const accountNav = JSON.parse(
          data[1].Properties.SerializedSelectedPages
        );
        const footer = data
          .filter((item: any) => item.Properties.Container_Label === 'Footer')
          .map((item: any) => item.Children)[0]
          .map((item: any) => {
            return {
              id: item.Id,
              name: item.Properties.Container_Label,
              className: item.Properties.Container_Css,
              children: item.Children,
            };
          });

        return { mainNav, accountNav, footer };
      },
    }
  );
  return { navigationData, isLoading };
};

export default useMainNavigationQuery;
