import './DocumentCenter.scss';
import { useTranslation } from 'react-i18next';
import BreadcrumbLayout from '../../layouts/BreadcrumbLayout/BreadcrumbLayout';
import TwoColumnCardGrid from '../../components/TwoColumnCardGrid/TwoColumnCardGrid';
import { ResourceLinkProps } from '../../interfaces/ResourceLink';
import ResourceLink from '../../components/ResourceLink/ResourceLink';
import useEpicApiStore from '../../store/useEpicApiStore';

const DocumentCenter = () => {
  const { t } = useTranslation();
  const { isPPO, premium, noPlansExperience } = useEpicApiStore();

  let cards: ResourceLinkProps[] = t('account.documentCenter.cards', {
    returnObjects: true,
  });

  if (premium?.hasBilling && Array.isArray(cards)) {
    cards.unshift({
      text: t('account.documentCenter.billingLinkText'),
      to: `/${t('routes.billing.index')}`,
      openInNewTab: false,
    });
  }

  if (noPlansExperience && Array.isArray(cards)) {
    const linkToMatch = [
      `/${t('routes.billing.index')}`,
      `/${t('routes.coverage.index')}/${t('routes.coverage.referrals')}`,
      `/${t('routes.coverage.index')}/${t(
        'routes.coverage.precertifications'
      )}`,
      `/${t('routes.coverage.index')}/${t('routes.coverage.documents')}`,
    ];

    cards = cards.filter(
      card => !linkToMatch.includes(card.to as string) && card
    );
  }

  if (isPPO) {
    const precertifications: ResourceLinkProps[] = t(
      'account.documentCenter.cardPrecertifications',
      {
        returnObjects: true,
      }
    );
    cards.splice(0, 1, precertifications[0]);
  }

  return (
    <BreadcrumbLayout
      pageTitle={`${t('account.documentCenter.title')}`}
      className="document-center"
    >
      <div data-testid="document-center">
        <div className="container document-center__forms">
          <TwoColumnCardGrid>
            <>
              {Array.isArray(cards) &&
                cards.map((link, index) => (
                  <ResourceLink {...link} key={index} />
                ))}
            </>
          </TwoColumnCardGrid>
        </div>
      </div>
    </BreadcrumbLayout>
  );
};

export default DocumentCenter;
