import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import useEpicApiStore from '../../store/useEpicApiStore';
import './PlanSpending.scss';
import { Helper } from '@shp/shp-epic';
import { useTranslatedPlanSpending } from '../../hooks/useTranslatedPlanSpending';
import { Link } from 'react-router-dom';
import { TranslatedAccumulator } from '../../interfaces/TranslatedAccumulator';
import IconInformation from '../ShpIcons/IconInformation';
import InformationModal from '../InformationModal/InformationModal';
import TabMenu, { Tab } from '../TabMenu/TabMenu';

interface Accumulator {
  spent: number;
  total: number;
}

interface PlanSpendingElementProps {
  title: string;
  accumulator: Accumulator;
  hasFamilyAccumulators: boolean | undefined;
}

interface ProgressBarProps {
  percentFilled: number;
}

interface PlanSpendingProps {}

const PlanSpending: FC<PlanSpendingProps> = () => {
  const { t } = useTranslation();
  const { isPPO, planSpending, networkName } = useEpicApiStore();
  const [isModalOpen, setModalOpen] = React.useState(false);

  const defaultPlanSpending = {
    untiered: [],
    tier1: [],
    tier2: [],
    tier3: [],
    hasTiers: false,
    hasFamilyAccumulators: false,
    hasFamilyRxAccumulators: false,
  };

  const spendingItems = useTranslatedPlanSpending(
    planSpending || defaultPlanSpending
  );

  let tiers = [
    {
      name: 'Tier 1: ' + networkName + ' Network',
      planSpending: spendingItems?.tier1,
    },
    {
      name: 'Tier 2: Aetna Open Choice PPO Network',
      planSpending: spendingItems?.tier2,
    },
    {
      name: 'Tier 3: Out-of-network',
      planSpending: spendingItems?.tier3,
    },
  ];

  if (isPPO) {
    tiers = [
      {
        name: 'Tier 1: Sharp Health Plan ' + networkName + ' Network',
        planSpending: spendingItems?.tier1,
      },
      {
        name: 'Tier 2: First Health Network',
        planSpending: spendingItems?.tier2,
      },
      {
        name: 'Tier 3: Out-of-network',
        planSpending: spendingItems?.tier3,
      },
    ];
  }

  const tabs = new Array<Tab>();

  const innerTabHeader = (
    <div className="plan-spending__header" key={'plan-spending-header'}>
      <h4 className="plan-spending__title">
        {t('home.coverage.planSpending.title')}
        <button
          className="information-modal-trigger"
          onClick={() => {
            setModalOpen(true);
          }}
        >
          <IconInformation />
          <span className="sr-only">{t('home.careTeamCards.information')}</span>
        </button>
      </h4>
      <Link to={`/${t('routes.balances')}`} className="plan-spending__link">
        {t('home.coverage.sectionAction')}
      </Link>
    </div>
  );

  const getAccumulatorsForTier = (tierIndex: number) => {
    return tiers[tierIndex].planSpending;
  };

  let elements: JSX.Element[] = [];

  if (planSpending && planSpending.hasTiers) {
    tiers.forEach((tier, index) => {
      const tierAccumulators = getAccumulatorsForTier(index);

      if (tierAccumulators && tierAccumulators.length > 0) {
        const tierHeader = (
          <h4 key={`tier-${index}`} className="plan-spending__tier-title">
            {tier.name}
          </h4>
        );

        const tabHeader = (
          <h3
            key={`tier-${index}`}
            className="plan-spending__tier-title in-tab"
          >
            <span
              dangerouslySetInnerHTML={{
                __html: tier.name.replace(
                  'Tier ' + (index + 1) + ':',
                  `<b>TIER ${index + 1}:</b> `
                ),
              }}
            />
          </h3>
        );

        elements.push(tierHeader);

        let tabContent: Array<JSX.Element> = [innerTabHeader];
        let accumulators: Array<JSX.Element> = [];

        tierAccumulators.forEach((accumulator: TranslatedAccumulator) => {
          const tierContent = (
            <PlanSpendingElement
              title={t(accumulator.translationKey)}
              accumulator={accumulator}
              hasFamilyAccumulators={planSpending?.hasFamilyAccumulators}
              key={`tier${index}-${accumulator.translationKey}`}
            />
          );
          accumulators.push(tierContent);
        });

        const accuContent = (
          <div key={`tier${index}-content`} className="plan-spending__list">
            {accumulators}
          </div>
        );

        elements.push(accuContent);
        tabContent.push(accuContent);

        tabs.push({
          tabTitleContent: tabHeader,
          tabContent: tabContent,
          index,
        });
      }
    });
  } else {
    spendingItems.untiered.forEach(accumulator => {
      elements.push(
        <PlanSpendingElement
          title={t(accumulator.translationKey)}
          accumulator={accumulator}
          hasFamilyAccumulators={planSpending?.hasFamilyAccumulators}
          key={accumulator.translationKey}
        />
      );
    });
  }

  if (elements.length === 0) return null;

  return (
    <div className="plan-spending" data-testid="PlanSpending">
      {planSpending && planSpending.hasTiers && (
        <>
          <TabMenu tabs={tabs}></TabMenu>
          <p className="plan-spending__note">
            <b>
              {t('home.coverage.planSpending.additionalNote.boldedPrefix') +
                ' '}
            </b>
            {t('home.coverage.planSpending.additionalNote.content')}
          </p>
        </>
      )}

      {!(planSpending && planSpending.hasTiers) && (
        <div className="plan-spending__body">
          <div className="plan-spending__header">
            <h3 className="plan-spending__title">
              {t('home.coverage.planSpending.title')}
              <button
                className="information-modal-trigger"
                onClick={() => {
                  setModalOpen(true);
                }}
              >
                <IconInformation />
                <span className="sr-only">
                  {t('home.careTeamCards.information')}
                </span>
              </button>
            </h3>
            <Link
              to={`/${t('routes.balances')}`}
              className="plan-spending__link"
            >
              {t('home.coverage.sectionAction')}
            </Link>
          </div>
          <div className="plan-spending__list">{elements}</div>
        </div>
      )}

      {isModalOpen && (
        <InformationModal
          title={t('home.coverage.planSpending.informationModal.title')}
          isOpen={isModalOpen}
          setIsOpen={setModalOpen}
        >
          <>
            <p
              key="modal-planSpending"
              dangerouslySetInnerHTML={{
                __html: t(
                  'home.coverage.planSpending.informationModal.description'
                ),
              }}
            ></p>
          </>
        </InformationModal>
      )}
    </div>
  );
};

const PlanSpendingElement: React.FC<PlanSpendingElementProps> = props => {
  const { t } = useTranslation();
  const accumulatorStyle = '50';
  const percentSpent =
    100 * (props.accumulator.spent / props.accumulator.total);
  const remainingAmt = props.accumulator.total - props.accumulator.spent;

  return (
    <div
      className={`accumulator accumulator__width-${accumulatorStyle}`}
      tabIndex={0}
      aria-label={`${props.title}: ${Helper.formatCurrency(
        props.accumulator.spent,
        true
      )}${' '}
  ${t('home.coverage.planSpending.spent')}${' - '}${Helper.formatCurrency(
        remainingAmt,
        true
      )}${` `}
  ${t('home.coverage.planSpending.remaining')}`}
    >
      <div className="accumulator__title">
        <h4>
          {props.title}
          <span>{': '}</span>
        </h4>
        <span>{Helper.formatCurrency(props.accumulator.total, true)}</span>
      </div>
      <ProgressBar percentFilled={percentSpent} />
      <p>
        <span>
          <span>{t('home.coverage.planSpending.spent')}</span>
          {': '}
          {Helper.formatCurrency(props.accumulator.spent, true)}
        </span>
        <span>
          <span className="accumulator__spending__suffix">
            {t('home.coverage.planSpending.remaining')}
            {`: `}
          </span>
          {Helper.formatCurrency(remainingAmt, true)}
        </span>
      </p>
    </div>
  );
};

const ProgressBar: React.FC<ProgressBarProps> = props => {
  return (
    <div className="progress-bar__container">
      <div
        className="progress-bar__inner-bar"
        style={{ width: props.percentFilled + '%' }}
      />
    </div>
  );
};

export default PlanSpending;
