import './Accordion.scss';
import { MouseEventHandler, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getRelatedSubcategories } from '../../api/faqApi';
import { useTranslation } from 'react-i18next';
import { FaqCategory, FaqCategoryTransform } from '../../interfaces/Faq';
import AccordionContent from './AccordionContent';
import IconToggleCaret from '../IconToggleCaret/IconToggleCaret';

type Props = {
  id: number;
  category: FaqCategoryTransform;
};

const Accordion = ({ id, category }: Props) => {
  const { i18n } = useTranslation();
  const locale = i18n.resolvedLanguage;
  const [isOpen, setIsOpen] = useState(false);
  const { data: subCategory, isLoading } = useQuery(
    [`sub-category-${category.url}`, locale],
    () => getRelatedSubcategories(category.id, locale),
    {
      select: data =>
        data.map((item: FaqCategory) => {
          return {
            id: item.Id,
            name: item.Name,
          };
        }),
    }
  );

  const handleFaqClick: MouseEventHandler<HTMLButtonElement> = event => {
    setIsOpen(current => !current);
  };

  return (
    <div className="accordion" key={id}>
      <h3 className="accordion__title">
        <button
          id={`accordion-${id}-id`}
          className="accordion__controls"
          onClick={e => handleFaqClick(e)}
          aria-expanded={isOpen}
          aria-controls={`section-${id}`}
        >
          <div>
            <div className="accordion__category">{category.name}</div>
            {!isLoading && subCategory ? (
              <div className="accordion__subcategory">
                {subCategory[0].name}
              </div>
            ) : null}
          </div>
          <span className={`accordion__icon ${isOpen ? '-open' : ''}`}>
            <IconToggleCaret />
          </span>
        </button>
      </h3>
      {isOpen && (
        <div
          id={`section-${id}`}
          className="accordion__content"
          aria-labelledby={`accordion-${id}-id`}
          role="region"
        >
          {!isLoading && subCategory ? (
            <AccordionContent subCategoryId={subCategory[0].id} />
          ) : null}
        </div>
      )}
    </div>
  );
};

export default Accordion;
