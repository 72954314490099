import './AccountDropdownMenu.scss';
import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import FocusTrap from 'focus-trap-react';
import { useWebPortalStore } from '../../store/WebPortalStore';
import { useTranslation } from 'react-i18next';
import IconToggleCaret from '../IconToggleCaret/IconToggleCaret';
import useAccountMenuNavigationData from '../../hooks/useAccountMenuNavigationData';
import useLogout from '../../hooks/useLogout';
// Renable when using SiteFinity for nav items
// import useMainNavigationQuery from '../../hooks/useMainNavigationQuery';
// import { PageNavigationItem } from '../../interfaces/Page';

const AccountDropdownMenu: React.FC = () => {
  const { t } = useTranslation();
  const { setIsAccountDropdownOpen } = useWebPortalStore();
  const handleLogout = useLogout();
  // This will be the hook to get the nav items from SiteFinity
  // const { navigationData, isLoading } = useMainNavigationQuery(
  //   i18n.resolvedLanguage
  // );
  const [expandedSections, setExpandedSections] = useState<string[]>([]);
  const toggleSection = (sectionName: string) => {
    setExpandedSections(prevState =>
      prevState.includes(sectionName)
        ? prevState.filter(name => name !== sectionName)
        : [...prevState, sectionName]
    );
  };

  const shouldDisplayCaret = (sectionName: string) => {
    return [
      t('accountDropdown.sharedAccounts'),
      t('accountDropdown.legalNotices'),
    ].includes(sectionName);
  };

  const navigationData = useAccountMenuNavigationData();

  window.addEventListener('keydown', event => {
    if (event.key === 'Escape') {
      setIsAccountDropdownOpen(false);
    }
  });

  return (
    <FocusTrap
      focusTrapOptions={{
        allowOutsideClick: true,
        fallbackFocus: '#account-dropdown',
      }}
    >
      <div
        id="account-dropdown"
        className="account-dropdown"
        data-testid="account-dropdown"
      >
        <div className="account-dropdown__top">
          <div className="account-dropdown__heading">
            {t('dashboardNav.accountDesktop')}
          </div>
        </div>
        <nav className="account-dropdown__nav">
          <ul className="account-dropdown__list">
            {navigationData.map(section => (
              <li
                key={section.name}
                className={`account-dropdown__section${
                  shouldDisplayCaret(section.name) ? ' -hasDropdown' : ''
                }`}
              >
                <div
                  className={`account-dropdown__section-header ${
                    expandedSections.includes(section.name) ? 'expanded' : ''
                  } ${shouldDisplayCaret(section.name) ? 'hasDropdown' : ''}`}
                  onClick={() =>
                    shouldDisplayCaret(section.name) &&
                    toggleSection(section.name)
                  }
                >
                  <h3 className="account-dropdown__header">{section.name}</h3>
                  {shouldDisplayCaret(section.name) && (
                    <div className="caret-icon">
                      <IconToggleCaret />
                    </div>
                  )}
                </div>
                {(shouldDisplayCaret(section.name)
                  ? expandedSections.includes(section.name)
                  : true) &&
                  section.links.map(link => (
                    <div
                      key={link.name}
                      className="account-dropdown__link-wrapper"
                    >
                      {!link.openInNewTab ? (
                        link.onClick ? (
                          <button
                            className="account-dropdown__link"
                            onClick={() => link.onClick && link.onClick()}
                          >
                            {link.name}
                          </button>
                        ) : (
                          <NavLink
                            className="account-dropdown__link"
                            to={link.link}
                          >
                            {link.name}
                          </NavLink>
                        )
                      ) : (
                        <Link
                          className="account-dropdown__link"
                          to={link.link}
                          target={link.openInNewTab ? '_blank' : '_self'}
                        >
                          {link.name}
                        </Link>
                      )}
                    </div>
                  ))}
              </li>
            ))}
            <li>
              <button className="account-dropdown__link" onClick={handleLogout}>
                {t('logoutBtn')}
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </FocusTrap>
  );
};

export default AccountDropdownMenu;
