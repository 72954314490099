import { useState, useEffect } from 'react';
import { Accumulator, CoverageInfo, PlanSpending } from '@shp/shp-epic';
import { TranslatedAccumulator } from '../interfaces/TranslatedAccumulator';
import { TranslatedPlanSpending } from '../interfaces/TranslatedPlanSpending';

const keyMap = new Map<keyof CoverageInfo, string>([
  ['individualMoopAccumulated', 'home.coverage.planSpending.individualMoop'],
  ['accountMoopAccumulated', 'home.coverage.planSpending.familyMoop'],
  [
    'individualDeductibleAccumulated',
    'home.coverage.planSpending.individualDeductible',
  ],
  [
    'accountDeductibleAccumulated',
    'home.coverage.planSpending.familyDeductible',
  ],
  [
    'individualRxDeductibleAccumulated',
    'home.coverage.planSpending.individualRxDeductible',
  ],
  [
    'accountRxDeductibleAccumulated',
    'home.coverage.planSpending.familyRxDeductible',
  ],
]);

const translateAccumulator = (
  accumulator: Accumulator
): TranslatedAccumulator => {
  return {
    translationKey: keyMap.get(accumulator.key) ?? accumulator.key,
    spent: accumulator.spent,
    total: accumulator.total,
  };
};

export function useTranslatedPlanSpending(
  planSpending: PlanSpending
): TranslatedPlanSpending {
  const [translatedPlanSpending, setTranslatedPlanSpending] =
    useState<TranslatedPlanSpending>({
      untiered: [],
      tier1: [],
      tier2: [],
      tier3: [],
      hasTiers: false,
      hasFamilyAccumulators: false,
      hasFamilyRxAccumulators: false,
    });

  useEffect(() => {
    setTranslatedPlanSpending({
      untiered: planSpending.untiered.map(translateAccumulator),
      tier1: planSpending.tier1.map(translateAccumulator),
      tier2: planSpending.tier2.map(translateAccumulator),
      tier3: planSpending.tier3.map(translateAccumulator),
      hasTiers: planSpending.hasTiers,
      hasFamilyAccumulators: planSpending.hasFamilyAccumulators,
      hasFamilyRxAccumulators: planSpending.hasFamilyRxAccumulators,
    });
  }, [planSpending]);

  return translatedPlanSpending;
}
