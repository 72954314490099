import {
  SharpForm,
  SharpAccountFormName,
  SharpClaimsFormName,
  SharpCoverageFormName,
} from '@shp/shp-epic/dist/EpicApi/Classes/Forms/Form';
import { PersonalUpdateItem } from '../types/sharedSHP';
import { ResourceLinkProps } from '../interfaces/ResourceLink';

/**
 * Matches and returns the correct route for Forms Assembly pages
 * @param urlString
 * @returns string
 *
 */
const getFormsAssemblyPath = (
  routeString: string,
  t: Function
): string | undefined => {
  if (!routeString.includes('/inside.asp?mode=fdiredirect&option')) {
    return undefined;
  }

  const isPlanChange = [
    'planChangeIFP',
    'planChangeMA',
    'planChangeSHCEGWP',
  ].some(value => routeString.includes(value));

  if (routeString.includes('option')) {
    if (isPlanChange) {
      return `/${t('routes.coverage.index')}/${t('routes.coverage.forms')}/${t(
        'routes.coverage.changeMyPlan'
      )}`;
    } else if (routeString.includes('option=ssnVerification')) {
      return `/${t('routes.account.index')}/${t(
        'routes.account.forms.index'
      )}/${t('routes.account.forms.ssnVerification')}`;
    } else if (routeString.includes('option=ssnCorrect')) {
      return `/${t('routes.account.index')}/${t(
        'routes.account.forms.index'
      )}/${t('routes.account.forms.ssnCorrection')}`;
    }
  }

  return undefined;
};

/**
 * Override for updatePlanName route
 * @param routeString
 * @param t
 * @returns string
 */
const getUpdatePlanNameRoute = (
  routeString: string,
  t: Function
): string | undefined => {
  if (
    '/inside.asp?mode=questionnaire&src=sys&srcid=1&quesid=1401000904' ===
    routeString
  ) {
    return `/${t('routes.account.index')}/${t(
      'routes.account.forms.index'
    )}/${t('routes.account.forms.planName')}`;
  }
  return undefined;
};

/**
 * Generates a route string based on the translation key
 * @param routeString
 * @param t
 * @returns string
 */
export const generateRoute = (routeString: string, t: Function): string => {
  return (
    getFormsAssemblyPath(routeString, t) ||
    getUpdatePlanNameRoute(routeString, t) ||
    routeString
      .split('/')
      .map(segment => (segment.startsWith('routes.') ? t(segment) : segment))
      .join('/')
  );
};

export const isExternalLink = (routeString: string): boolean => {
  return routeString.startsWith('https');
};

export const getAllForms = (
  forms: SharpForm[],
  t: Function
): PersonalUpdateItem[] => {
  const cards: PersonalUpdateItem[] = t('account.personalUpdatesBlock.cards', {
    returnObjects: true,
  });
  const formsMap = new Map<SharpAccountFormName, string | undefined>([
    [SharpAccountFormName.updateContact, cards[0].title],
    [SharpAccountFormName.updateName, cards[1].title],
    [SharpAccountFormName.ssnVerification, cards[2].title],
    [SharpAccountFormName.partnershipDeclaration, cards[3].title],
    [SharpAccountFormName.ssnCorrection, cards[4].title],
  ]);

  return forms.map(form => {
    const link = generateRoute(form.url || '', t);
    const title = formsMap.get(form.name as SharpAccountFormName);
    return {
      title,
      description: '',
      link,
      newWindow: isExternalLink(link),
    };
  });
};

export const getClaimsForms = (
  forms: SharpForm[],
  t: Function,
  pharmacyLink?: string
): ResourceLinkProps[] => {
  const formsMap = new Map<SharpClaimsFormName, string>([
    [
      SharpClaimsFormName.medicalReimbursement,
      t('claims.resourceSection.links.medicalReimbursement'),
    ],
    [
      SharpClaimsFormName.rxReimbursement,
      t('claims.resourceSection.links.rxReimbursement'),
    ],
    [
      SharpClaimsFormName.deductibleCreditRequest,
      t('claims.resourceSection.links.deductibleCredit'),
    ],
    [
      SharpClaimsFormName.atHomeCovid19,
      t('claims.resourceSection.links.atHomeCovid19'),
    ],
  ]);

  return forms.map(form => {
    const link =
      'pharmacy.rxReimbursement' === form.url
        ? pharmacyLink
        : generateRoute(form.url || '', t);
    const title = formsMap.get(form.name as SharpClaimsFormName);
    return {
      text: title || '',
      excerpt: '',
      to: link || '',
      openInNewTab: isExternalLink(link || ''),
    };
  });
};

export const getCoverageEditForms = (
  forms: SharpForm[],
  t: Function,
  pharmacyLink?: string
): ResourceLinkProps[] => {
  const formsMap = new Map<SharpCoverageFormName, string>([
    [SharpCoverageFormName.changeMyPlan, t('coverage.forms.forms.edit.title')],
    [
      SharpCoverageFormName.addDependents,
      t('coverage.forms.forms.dependentsAdd.title'),
    ],
    [
      SharpCoverageFormName.removeDependents,
      t('coverage.forms.forms.dependentsRemove.title'),
    ],
    [SharpCoverageFormName.cancel, t('coverage.forms.forms.terminate.title')],
  ]);

  return forms.map(form => {
    const link = generateRoute(form.url || '', t);
    const title = formsMap.get(form.name as SharpCoverageFormName);
    return {
      text: title || '',
      excerpt: '',
      to: link || '',
      openInNewTab: isExternalLink(link || ''),
    };
  });
};

export const getChangeMyPlanForm = (
  forms: SharpForm[],
  t: Function
): string | undefined => {
  const formsMap = new Map<SharpCoverageFormName, string>([
    [SharpCoverageFormName.changeMyPlan, t('coverage.forms.forms.edit.title')],
  ]);

  const form = forms.find(form => {
    const title = formsMap.get(form.name as SharpCoverageFormName);
    return title !== undefined;
  });

  return form?.url;
};
