import './Home.scss';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import HomeNav from '../../components/HomeNav/HomeNav';
import UserStatus from '../../components/UserStatus/UserStatus';
import PremiumCTA from '../../components/PremiumCTA/PremiumCTA';
import CostShares from '../../components/CostShares/CostShares';
import PlanSpending from '../../components/PlanSpending/PlanSpending';
import PrimaryIconLink from '../../components/PrimaryIconLink/PrimaryIconLink';
import IconPreventionWellness from '../../components/ShpIcons/IconPreventionWellness';
import CareTeamCards from '../../components/CareTeamCards/CareTeamCards';
import SupportCard from '../../components/SupportCard/SupportCard';
import useEpicApiStore from '../../store/useEpicApiStore';
import NoPlansExperience from '../NoPlansExperience/NoPlansExperience';
import IconQuestionMark from '../../components/ShpIcons/IconQuestionMark';

const Home: React.FC = () => {
  const { t } = useTranslation();
  const { userDetails, noPlansExperience, isPPO } = useEpicApiStore();

  if (noPlansExperience) {
    return <NoPlansExperience />;
  }

  return (
    <section aria-label="Home" className="home" data-testid="Home">
      <nav className="home__nav-container" aria-labelledby="Home Navigation">
        <div className="container">
          <div className="home__header">
            <h1 className="home__title">
              {t('dashboardNav.accountGreeting', {
                name: userDetails?.firstName || t('dashboardNav.account'),
              })}
            </h1>
            <UserStatus />
            <Link
              to={`/${t('routes.account.index')}`}
              className="home__account-link"
            >
              {t('home.editAccountLink')}
            </Link>
          </div>
          <HomeNav />
        </div>
      </nav>

      <PremiumCTA />
      <PlanSpending />
      <CostShares />
      <CareTeamCards />

      <div className={isPPO ? 'gray-bg' : ''}>
        <div className="home__cta container">
          <PrimaryIconLink
            to={`/${t('routes.coverage.index')}/${t(
              'routes.coverage.wellness'
            )}`}
            title={t('home.cta.button.title')}
            text={t('home.cta.button.description')}
          >
            <IconPreventionWellness />
          </PrimaryIconLink>

          {isPPO && (
            <PrimaryIconLink
              to={t('home.cta.ppoInformation.link')}
              title={t('home.cta.ppoInformation.title')}
              text={t('home.cta.ppoInformation.description')}
              openInNewTab={true}
            >
              <IconQuestionMark />
            </PrimaryIconLink>
          )}

          <SupportCard />
        </div>
      </div>
    </section>
  );
};

export default Home;
