import './CostShares.scss';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useEpicApiStore from '../../store/useEpicApiStore';
import useTranslatedCostShares from '../../hooks/useTranslatedCostShares';
import IconInformation from '../ShpIcons/IconInformation';
import InformationModal from '../InformationModal/InformationModal';
import TabMenu, { Tab } from '../TabMenu/TabMenu';
import CostSharesList from '../CostSharesList/CostSharesList';

interface CostSharesProps {}

export const CostShareElement = (props: {
  name: string | undefined;
  amount: string | undefined;
  deductibleApplies: boolean | undefined;
}) => {
  const testID = 'Cost Share Element ' + props.name;

  return (
    <li
      className="cost-shares__item"
      key={props.name}
      data-testid={testID}
      tabIndex={0}
      aria-label={`${props.name} amount: ${props.amount}`}
    >
      <span className="cost-shares__item__title">{props.name}</span>
      <span className="cost-shares__item__value">
        {props.amount}

        {props.deductibleApplies && (
          <span className="cost-shares__item__deductible">1</span>
        )}
      </span>
    </li>
  );
};

const CostShares: FC<CostSharesProps> = () => {
  const { t } = useTranslation();
  const { costShares, isPPO, networkName } = useEpicApiStore();
  const [isModalOpen, setModalOpen] = React.useState(false);

  const defaultCostShares = {
    untiered: [],
    tier1: [],
    tier2: [],
    tier3: [],
    hasTiers: false,
  };

  const translatedCostShares = useTranslatedCostShares(
    costShares || defaultCostShares
  );

  let tiers = [
    {
      name: 'Tier 1: ' + networkName + ' Network',
      costShares: translatedCostShares?.tier1,
    },
    {
      name: 'Tier 2: Aetna Open Choice PPO Network',
      costShares: translatedCostShares?.tier2,
    },
    {
      name: 'Tier 3: Out-of-network',
      costShares: translatedCostShares?.tier3,
    },
  ];

  if (isPPO) {
    tiers = [
      {
        name: 'Tier 1: Sharp Health Plan ' + networkName + ' Network',
        costShares: translatedCostShares?.tier1,
      },
      {
        name: 'Tier 2: First Health Network',
        costShares: translatedCostShares?.tier2,
      },
      {
        name: 'Tier 3: Out-of-network',
        costShares: translatedCostShares?.tier3,
      },
    ];
  }

  const tabs = new Array<Tab>();

  const innerTabHeader = (
    <div
      className="cost-shares__body__header in-tab"
      key={'cost-shares-header'}
    >
      <h4 className="cost-shares__body__title">
        {t('home.coverage.costShares.title')}
        <button
          className="information-modal-trigger"
          onClick={() => {
            setModalOpen(true);
          }}
        >
          <IconInformation />
          <span className="sr-only">{t('home.careTeamCards.information')}</span>
        </button>
      </h4>
      <Link
        to={`/${t('routes.coverage.index')}?benefits=true#${t(
          'routes.coverage.myCoverageId'
        )}`}
        className="cost-shares__body__header__link"
      >
        {t('home.coverage.sectionAction')}
      </Link>
    </div>
  );

  const hasMoreThanOneTierWithCostShares =
    tiers.filter(tier => (tier.costShares?.length ?? 0) > 0).length > 1;

  const hasDeductibleApplied = tiers.some(
    tier =>
      tier.costShares?.some(costShare => costShare.deductibleApplies) ?? false
  );

  if (costShares && costShares.hasTiers) {
    tiers.forEach((tier, index) => {
      if (tier.costShares) {
        const tabHeader = (
          <h3 key={`tier-${index}`} className="cost-shares__tier-title in-tab">
            <span
              dangerouslySetInnerHTML={{
                __html: tier.name.replace(
                  'Tier ' + (index + 1) + ':',
                  `<b>TIER ${index + 1}:</b> `
                ),
              }}
            />
          </h3>
        );

        let tabContent: Array<JSX.Element> = [innerTabHeader];

        const costShareTabContent = (
          <li
            className="cost-shares__tier"
            key={'cost-share-tab-content-' + index}
          >
            <CostSharesList costShares={tier.costShares} />
          </li>
        );

        tabs.push({
          tabTitleContent: tabHeader,
          tabContent: [...tabContent, costShareTabContent],
          index,
        });
      }
    });
  }

  const bodyClass =
    !(costShares && costShares.hasTiers) && hasDeductibleApplied
      ? 'cost-shares__body with-disclaimer'
      : 'cost-shares__body';

  return (
    <>
      <div className="cost-shares" data-testid="CostShares">
        {costShares && costShares.hasTiers && <TabMenu tabs={tabs}></TabMenu>}

        {!(costShares && costShares.hasTiers) && (
          <div className={bodyClass}>
            <div className="cost-shares__body__header">
              <h2 className="cost-shares__body__title">
                {t('home.coverage.costShares.title')}
                <button
                  className="information-modal-trigger"
                  onClick={() => {
                    setModalOpen(true);
                  }}
                >
                  <IconInformation />
                  <span className="sr-only">
                    {t('home.careTeamCards.information')}
                  </span>
                </button>
              </h2>
              <Link
                to={`/${t('routes.coverage.index')}?benefits=true#${t(
                  'routes.coverage.myCoverageId'
                )}`}
                className="cost-shares__body__header__link"
              >
                {t('home.coverage.sectionAction')}
              </Link>
            </div>
            <ul className="cost-shares__list">
              {tiers.map((tier, index) => {
                if (tier.costShares && tier.costShares.length > 0) {
                  return (
                    <li className="cost-shares__tier" key={index}>
                      <>
                        {hasMoreThanOneTierWithCostShares && (
                          <h3 className="cost-shares__tier-title">
                            {tier.name}
                          </h3>
                        )}
                        <CostSharesList costShares={tier.costShares} />
                      </>
                    </li>
                  );
                }
                return null;
              })}
            </ul>
          </div>
        )}

        {hasDeductibleApplied && (
          <p className="cost-shares__disclaimer">
            {t('home.coverage.costShares.disclaimer')}
          </p>
        )}

        {isModalOpen && (
          <InformationModal
            title={t('home.coverage.costShares.informationModal.title')}
            isOpen={isModalOpen}
            setIsOpen={setModalOpen}
          >
            <>
              <p key="modal-costshares">
                {t('home.coverage.costShares.informationModal.description')}
              </p>
            </>
          </InformationModal>
        )}
      </div>
    </>
  );
};

export default CostShares;
