import './NavigationMobile.scss';
import { useTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';
import { MouseEventHandler } from 'react';
import { PageNavigationItem } from '../../interfaces/Page';
import useMainNavigationQuery from '../../hooks/useMainNavigationQuery';
import IconRightArrow from '../IconRightArrow/IconRightArrow';
import IconToggleCaret from '../IconToggleCaret/IconToggleCaret';
import FocusTrap from 'focus-trap-react';
import useEpicApiStore from '../../store/useEpicApiStore';
import { usePharmacyData } from '../../hooks/usePharmacy';
import { useWebPortalStore } from '../../store/WebPortalStore';
import useLogout from '../../hooks/useLogout';
import useAccountMenuNavigationData from '../../hooks/useAccountMenuNavigationData';

const NavigationMobile = () => {
  const { t, i18n } = useTranslation();
  const locale = i18n.resolvedLanguage;
  const billingUri = t('routes.billing.index');
  const pharmacyUri = t('routes.pharmacy.index');
  const handleLogout = useLogout();
  const { premium } = useEpicApiStore();
  const pharmacy = usePharmacyData();
  const { isMobileAccountDropdownOpen, setIsMobileAccountDropdownOpen } =
    useWebPortalStore();

  const { navigationData, isLoading } = useMainNavigationQuery(locale);
  const accountMenuData = useAccountMenuNavigationData();

  const handleSubMenuClick: MouseEventHandler<HTMLButtonElement> = event => {
    setIsMobileAccountDropdownOpen(!isMobileAccountDropdownOpen);
  };

  return (
    <FocusTrap
      focusTrapOptions={{
        allowOutsideClick: true,
        fallbackFocus: '#mobile-navigation',
      }}
    >
      <div
        id="mobile-navigation"
        className="mobile-navigation"
        data-testid="mobile-navigation"
      >
        {!isLoading && navigationData ? (
          <nav>
            <ul className="mobile-navigation__list" role="menu">
              <li className="mobile-navigation__item" role="none">
                <NavLink
                  className="mobile-navigation__link"
                  to={`${t('routes.notifications')}`}
                  role="menu-item"
                >
                  {t('dashboardNav.notifications')}
                  <IconRightArrow />
                </NavLink>
              </li>
              <li className="mobile-navigation__item" role="none">
                <NavLink
                  className="mobile-navigation__link"
                  to={`${t('routes.messages')}`}
                  role="menu-item"
                >
                  {t('dashboardNav.messages')}
                  <IconRightArrow />
                </NavLink>
              </li>
              <li className="mobile-navigation__item" role="none">
                <button
                  className="mobile-navigation__link"
                  onClick={e => handleSubMenuClick(e)}
                  aria-haspopup="true"
                  aria-controls="mobile-sub-menu"
                  aria-expanded={isMobileAccountDropdownOpen}
                >
                  {t('dashboardNav.account')}
                  <span
                    className={`accordion__icon ${
                      isMobileAccountDropdownOpen ? '-open' : ''
                    }`}
                  >
                    <IconToggleCaret />
                  </span>
                </button>

                {isMobileAccountDropdownOpen && (
                  <FocusTrap
                    focusTrapOptions={{
                      allowOutsideClick: true,
                      fallbackFocus: '#mobile-sub-menu',
                    }}
                  >
                    <ul
                      id="mobile-sub-menu"
                      className="mobile-navigation__sublist"
                      role="menu"
                    >
                      {accountMenuData.map((section, index) => (
                        <li key={index}>
                          <h3 className="mobile-navigation__header">
                            {section.name}
                          </h3>
                          <ul className="mobile-navigation__sub-section">
                            {section.links.map(item => (
                              <li key={item.name}>
                                <Link
                                  className="mobile-navigation__link"
                                  to={item.link}
                                  role="menu-item"
                                >
                                  {item.name}
                                  <IconRightArrow />
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </li>
                      ))}
                    </ul>
                  </FocusTrap>
                )}
              </li>
              {navigationData.mainNav.map((item: PageNavigationItem) =>
                (item.UrlName !== pharmacyUri && item.UrlName !== billingUri) ||
                (item.UrlName === pharmacyUri && pharmacy?.hasPharmacy) ||
                (item.UrlName === billingUri && premium?.hasBilling) ? (
                  <li
                    className="mobile-navigation__item"
                    key={item.Id}
                    role="none"
                  >
                    <NavLink
                      className="mobile-navigation__link"
                      to={item.UrlName}
                      aria-current="page"
                      role="menu-item"
                    >
                      {item.Title.Value}
                      <IconRightArrow />
                    </NavLink>
                  </li>
                ) : (
                  ''
                )
              )}
              <li>
                <button
                  className="mobile-navigation__link"
                  onClick={handleLogout}
                >
                  <strong>{t('logoutBtn')}</strong>
                  <IconRightArrow />
                </button>
              </li>
            </ul>
          </nav>
        ) : (
          ''
        )}
      </div>
    </FocusTrap>
  );
};

export default NavigationMobile;
