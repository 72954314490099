import './Claims.scss';
import { useTranslation } from 'react-i18next';
import { usePharmacyData } from '../../hooks/usePharmacy';
import EpicIframe from '../../components/EpicIframe/EpicIframe';
import BreadcrumbLayout from '../../layouts/BreadcrumbLayout/BreadcrumbLayout';
import ResourceLink from '../../components/ResourceLink/ResourceLink';
import PrimaryIconLink from '../../components/PrimaryIconLink/PrimaryIconLink';
import IconPharmacyRX from '../../components/ShpIcons/IconPharmacyRX';
import useScrollToView from '../../hooks/useScrollToView';
import useEpicApiStore from '../../store/useEpicApiStore';
import { getClaimsForms } from '../../helpers/sharedLibrary';
import { Link } from 'react-router-dom';

const Claims = () => {
  const { t } = useTranslation();
  const { planInfo, noPlansExperience, hasMedicare } = useEpicApiStore();
  const pharmacy = usePharmacyData();
  const formsId = t('routes.claims.formsSectionId');
  const formsRef = useScrollToView({ id: formsId });

  const forms = getClaimsForms(
    planInfo?.forms.claims || [],
    t,
    pharmacy?.rxReimbursement
  );

  if (!pharmacy?.hasPharmacy) {
    delete forms[1];
  }

  // if not hasMedicare delete athomecovid, which should be the last item.
  if (hasMedicare) {
    forms.pop();
  }

  return (
    <div data-testid="claims-page">
      <BreadcrumbLayout pageTitle={t('claims.pageName')} className="claims">
        <>
          <div className="container claims__data-tile">
            {noPlansExperience && <p>{t('noPlansExperience.text')}</p>}
            <EpicIframe title="Claims" url="Claims" width={500} height={640} />
            {!hasMedicare && (
              <p className="claims__disclaimer">
                {t('claims.processedText.text')}{' '}
                <Link
                  to={`/${t('routes.account.index')}/${t(
                    'routes.account.documentCenter'
                  )}/${t('routes.account.correspondence')}`}
                >
                  {t('claims.processedText.textLink')}
                </Link>
                {'.'}
              </p>
            )}
          </div>
          <div>
            <div className="container claims__forms">
              {pharmacy?.hasPharmacy && (
                <PrimaryIconLink
                  to={pharmacy.claims || ''}
                  title={t('claims.pharmacyClaims.title')}
                  text={t('claims.pharmacyClaims.description')}
                  openInNewTab={true}
                >
                  <IconPharmacyRX />
                </PrimaryIconLink>
              )}
              <h3 id={formsId} className="claims__forms-heading" ref={formsRef}>
                {t('claims.resourceSection.title')}
              </h3>
              <ul className="claims__forms-list">
                {forms.map((form, index) => (
                  <li key={index}>
                    <ResourceLink {...form} />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </>
      </BreadcrumbLayout>
    </div>
  );
};

export default Claims;
