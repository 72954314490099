import './FooterBadges.scss';
import { useTranslation } from 'react-i18next';
import insuranceBadge from '../../assets/images/health-insurance-badge.png';
import usNewsBadge from '../../assets/images/2024_us-news-medicare_100x100.png';
import shpBadge from '../../assets/images/shp-badge.png';

const FooterBadges = () => {
  const { t } = useTranslation();

  const badges = [
    {
      alt: t('footerBadges.usNews'),
      src: usNewsBadge,
      id: 'us-news-badge',
    },
    {
      alt: t('footerBadges.shp'),
      src: shpBadge,
      id: 'shp-badge',
    },
    {
      alt: t('footerBadges.healthInsurance'),
      src: insuranceBadge,
      id: 'health-insurance-badge',
    },
  ];

  return (
    <div className="footer-badges">
      {badges.map(badge => (
        <div className={`footer-badges__badge -${badge.id}`} key={badge.id}>
          <img src={badge.src} alt={badge.alt} />
        </div>
      ))}
    </div>
  );
};

export default FooterBadges;
