import './Navigation.scss';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PageNavigationItem } from '../../interfaces/Page';
import useMainNavigationQuery from '../../hooks/useMainNavigationQuery';
import useEpicApiStore from '../../store/useEpicApiStore';
import { usePharmacyData } from '../../hooks/usePharmacy';

export default function Navigation() {
  const { t, i18n } = useTranslation();
  const { premium, noPlansExperience } = useEpicApiStore();
  const pharmacyInfo = usePharmacyData();
  const locale = i18n.resolvedLanguage;
  const billingUri = t('routes.billing.index');
  const pharmacyUri = t('routes.pharmacy.index');
  const { navigationData, isLoading } = useMainNavigationQuery(locale);

  if (noPlansExperience && navigationData) {
    const navItemsToFilterOut = [
      t('routes.coverage.index'),
      t('routes.findCare.index'),
      t('routes.billing.index'),
      t('routes.pharmacy.index'),
    ];

    const filterOutPattern = new RegExp(navItemsToFilterOut.join('|'));

    navigationData.mainNav = navigationData.mainNav.filter(
      (item: PageNavigationItem) => !item.UrlName.match(filterOutPattern)
    );
  }

  return (
    <div className="main-navigation" data-testid="main-navigation">
      <nav className="main-navigation__container">
        {!isLoading && navigationData ? (
          <ul className="main-navigation__list">
            {navigationData.mainNav.map((item: PageNavigationItem) =>
              (item.UrlName !== pharmacyUri && item.UrlName !== billingUri) ||
              (item.UrlName === pharmacyUri && pharmacyInfo?.hasPharmacy) ||
              (item.UrlName === billingUri && premium?.hasBilling) ? (
                <li className="main-navigation__item" key={item.Id}>
                  <NavLink
                    className={({ isActive }) =>
                      isActive
                        ? 'main-navigation__link -active'
                        : 'main-navigation__link'
                    }
                    aria-current="page"
                    to={item.UrlName}
                  >
                    {item.Title.Value}
                  </NavLink>
                </li>
              ) : (
                ''
              )
            )}
          </ul>
        ) : (
          ''
        )}
      </nav>
    </div>
  );
}
