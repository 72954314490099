import React from 'react';

interface ScrollLinkProps {
  targetId: string;
  behavior: ScrollBehavior;
  children: React.ReactNode;
}

const ScrollToLink = ({ targetId, behavior, children }: ScrollLinkProps) => {
  const scrollToTarget = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    const targetElement = document.getElementById(targetId);

    if (targetElement) {
      targetElement.scrollIntoView({ behavior });
    }
  };

  return (
    <a href={`#${targetId}`} onClick={scrollToTarget}>
      {children}
    </a>
  );
};

export default ScrollToLink;
