import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { getRelatedQuestions } from '../../api/faqApi';
import { FaqQuestion } from '../../interfaces/Faq';

type Props = {
  subCategoryId: string;
};

const AccordionContent = ({ subCategoryId }: Props) => {
  const { i18n } = useTranslation();
  const locale = i18n.resolvedLanguage;
  const { data: questions, isLoading } = useQuery(
    [[`faq-questions-${subCategoryId}`], locale],
    () => getRelatedQuestions(subCategoryId, locale)
  );
  return (
    <div>
      {!isLoading && questions
        ? questions.map((question: FaqQuestion) => (
            <div key={question.Id}>
              <div
                dangerouslySetInnerHTML={{ __html: question.QuestionText }}
              />
              <div dangerouslySetInnerHTML={{ __html: question.AnswerText }} />
            </div>
          ))
        : null}
    </div>
  );
};

export default AccordionContent;
