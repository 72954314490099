import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useEpicApiStore from '../store/useEpicApiStore';

export function useBehavioralHealthUrl(): string {
  const { t } = useTranslation();
  const { hasMedicare, isCalpersEGWP, isCalpers } = useEpicApiStore();
  const [url, setUrl] = useState<string>('');

  useEffect(() => {
    let computedUrl = t('behavioralHealthLink.default');

    if (isCalpersEGWP) {
      computedUrl = t('behavioralHealthLink.calpersEGWP');
    } else if (hasMedicare) {
      computedUrl = t('behavioralHealthLink.medicare');
    } else if (isCalpers) {
      computedUrl = t('behavioralHealthLink.calpers');
    }

    setUrl(computedUrl);
  }, [t, isCalpersEGWP, hasMedicare, isCalpers]);

  return url;
}
