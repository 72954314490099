import { useMemo } from 'react';
import useEpicApiStore from './../store/useEpicApiStore';
import { HealthTrio, ProviderType, Specialty } from '@shp/shp-epic';

export function useHealthTrio() {
  const { provider, networkName, hasMedicare } = useEpicApiStore();
  const pmg = provider?.pmgName || '';
  const network = networkName || '';
  const npi = provider?.npi || '';

  const healthTrioFilteredSearchLinks = useMemo(() => {
    let primaryPhysicianUrl = new URL(HealthTrio.BASE_URL);

    primaryPhysicianUrl.searchParams.append('searchString', npi);

    return {
      doctorSearch: HealthTrio.getFilterOnProviderTypeUrl(
        pmg,
        network,
        ProviderType.PRIMARY_CARE
      ),
      specialistSearch: HealthTrio.getFilterOnProviderTypeUrl(
        pmg,
        network,
        ProviderType.SPECIALIST
      ),
      behavioralHealthSearch: HealthTrio.getFilterOnProviderTypeUrl(
        pmg,
        network,
        ProviderType.BEHAVIORAL_HEALTH,
        hasMedicare
      ),
      urgentCareSearch: HealthTrio.getFilterOnSpecialty(
        pmg,
        network,
        Specialty.URGENT_CARE
      ),
      findHospitalSearch: HealthTrio.getFilterOnSpecialty(
        pmg,
        network,
        Specialty.HOSPITAL
      ),
      labSearch: HealthTrio.getFilterOnSpecialty(pmg, network, Specialty.LAB),
      networkSearch: HealthTrio.getFilterOnNetworkUrl(network),
      medicalGroupSearch: HealthTrio.getFilterOnPmgAndNetwork(pmg, network),
      myPrimaryCarePhysicianSearch: primaryPhysicianUrl.toString(),
    };
  }, [network, pmg, npi, hasMedicare]);

  return healthTrioFilteredSearchLinks;
}
